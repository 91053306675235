<template>
  <VDialog
    v-model="dialog"
    scrollable
    max-width="960"
  >
    <template #activator="{ on, attrs }">
      <VBtn
        color="primary"
        :class="classes"
        :title="$t('$app.page_help')"
        v-bind="attrs"
        v-on="on"
      >
        Файлы ({{ certificate?.files_count }})
      </VBtn>
    </template>
    <VCard
      class="certificate-files"
      :loading="loading"
    >
      <VCardTitle>
        Прикрепленные файлы
        <VSpacer />
        <VBtn
          :title="$t('close.one')"
          icon
          @click="dialog=false"
        >
          <VIcon>close</VIcon>
        </VBtn>
      </VCardTitle>
      <VCardText v-if="files && files.length">
        <VDataTable
          :headers="headers"
          :items="files"
          :loading="loading"
          item-key="key"
          hide-default-footer
        >
          <template #[`item.title`]="{ item }">
            <td :style="{ width: '70%' }">
              <a
                :href="'/' + item.uri"
                target="_blank"
              >
                {{ item.title }}
              </a>
            </td>
          </template>

          <template #[`item.size`]="{ item }">
            <td>
              {{ formatSize(item.size) }}
            </td>
          </template>

          <template #[`item.action`]="{ item }">
            <td>
              <a
                :href="'/' + item.uri"
                target="_blank"
              >
                <VBtn
                  icon
                  title="Предпросмотр"
                >
                  <VIcon>pageview</VIcon>
                </VBtn>
              </a>

              <a
                :href="'/' + item.uri"
                :download="item.title"
                title="Скачать"
              >
                <VBtn icon>
                  <VIcon>download</VIcon>
                </VBtn>
              </a>
            </td>
          </template>
        </VDataTable>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<script>
import { getCertificateFiles } from '@/api/api'
import documentTitle from '@/mixins/documentTitle'
import { formatSize } from '@/utils/common'

export default {
  name: 'TheCertificateFiles',

  mixins: [documentTitle],

  props: {
    classes: {
      type: String,
      default: '',
    },

    certificate: {
      type: Object,
      default: () => {},
    }
  },

  data () {
    return {
      dialog: false,
      documentTitle: '',
      files: undefined,
      loading: true
    }
  },

  computed: {
    headers () {
      return [
        {
          text: this.$t('files.one'),
          value: 'title',
          sortable: false
        },
        {
          text: 'Размер',
          value: 'size',
          sortable: false
        },
        {
          text: '',
          value: 'action',
          sortable: false
        },
      ]
    }
  },

  watch: {
    dialog () {
      if (this.files === undefined) {
        this.getFiles()
      }
    }
  },

  methods: {
    formatSize,

    async getFiles () {
      const id = this.$route.params.id

      if (!id) return

      this.loading = true

      try {
        const { files } = await getCertificateFiles(id)

        this.files = files || []
      } finally {
        this.loading = false
      }
    },

  }
}
</script>
